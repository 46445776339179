import Column from "@/Components/Backend/Grid/Column";
import Container from "@/Components/Backend/Grid/Container";
import Row from "@/Components/Backend/Grid/Row";
import ContactForm from "@/Components/Front/Blocks/ContactForm/ContactForm";
import HeroWithBoxes from "@/Components/Front/Blocks/Hero/HeroWithBoxes";
import IconHeadingSection from "@/Components/Front/Blocks/IconHeadingSection/IconHeadingSection";
import InvestmentDescription from "@/Components/Front/Blocks/InvestmentDescription/InvestmentDescription";
import LatestNews from "@/Components/Front/Blocks/LatestNews/LatestNews";
import LocationMap from "@/Components/Front/Blocks/LocationMap/LocationMap";
import GalleryCarousel from "@/Components/Front/Blocks/Sliders/GalleryCarousel/GalleryCarousel";
import Text from "@/Components/Front/Text";
import WebpPicture from "@/Components/WebpPicture";
import MainLayout from "@/Layouts/MainLayout";
import { HomeProps } from "@/types/pages/home";
import { Head, Link } from "@inertiajs/react";

import MainImageHero from '@static/hero-home-idea.jpg?quality=85w=1920&format=webp&imagetools';
// import Box2Hero from '@static/box-2.jpg?quality=85&format=webp&w=200&h=200&imagetools';
import { Icon, InlineIcon } from "@iconify/react/dist/iconify.js";
import InvestmentDescriptionImage from '@static/Photo-investment.jpg?quality=85&format=webp&w=1920h=600&imagetools';
import AnimatedBox from '@static/animowanie-mieszkania.gif';
import Box2Hero from '@static/box-last-apartments.png?quality=85&format=webp&w=200&h=200&imagetools';
import BoxHeroAurora from '@static/mieszkania-aurora-block.png?quality=85&format=webp&w=200&h=200&imagetools';

const Home = ({ news, diaryOfBuilds, estates, choosenApartments }: HomeProps) => {
    const showPromo = true;

    return (
        <MainLayout>
            <Head>
                <title>Osiedle Idea od Unidevelopment</title>
                <meta name="description" content="Osiedle Idea od Unidevelopment" />
            </Head>
            <section>
                <HeroWithBoxes
                    hero={MainImageHero}
                    boxes={[
                        // { decor: true, image: Box3Hero, link: 'https://aurorakredyt.osiedleidea.pl', asExternal: true },
                        // { decor: false, image: Box1Hero, link: "#contact", asHash: true },
                        { decor: false, image: Box2Hero, link: "/poznaj-nasze-osiedla/ogrody" },
                        { decor: false, image: AnimatedBox, link: "#choosen-apartments", asHash: true },
                        { decor: false, image: BoxHeroAurora, link: "/poznaj-nasze-osiedla/aurora#gallery" },
                    ]}
                    texts={
                        <div className="md:mt-20 mt-10 md:mb-26 mb-13">
                            <Text heading="h1" className="mb-4 text-i-black [&>p>span]:font-bold">
                                <h1>Witaj na Osiedlu Idea</h1>
                            </Text>
                            <Text heading="p" className="text-i-black">
                                <p>Osiedle Idea w Radomiu to część spójnej wizji architektonicznej realizowanej przez Unidevelopment od 2016 roku. W jej ramach na zielonym Wacynie tworzymy komfortową dzielnicę mieszkaniową położoną blisko centrum (zaledwie 2,8 km), z kameralnymi budynkami, wygodnymi drogami i pięknymi terenami zielonymi. Projekt osiedla nawiązuje do nowoczesnej koncepcji urbanistycznej <strong>miasta 15-minutowego</strong> realizowanej obecnie z powodzeniem, m.in. w Paryżu.</p>
                            </Text>
                        </div>
                    }
                />
                {/* <HeroWithSlider
                    slides={[
                        // {
                        //     background: "/storage/img/hero/aurora_kokarda.webp",
                        //     box: {
                        //         heading: "<span>Rusza sprzedaż nowego etapu Osiedla Idea",
                        //         colorVariant: 'red',
                        //         button: <Link title="Rusza sprzedaż nowego etapu Osiedla Idea" href="/poznaj-nasze-osiedla/aurora">Zobacz</Link>
                        //     }
                        // },
                        // {
                        //     background: "/storage/img/hero/plac-centralny-hero.webp",
                        //     box: {
                        //         heading: "<span>Plac centralny</span><br/> Relaks w sercu Osiedla Idea",
                        //         desc: "Rozpoczęcie prac związanych z budową tego terenu zaplanowane jest jeszcze w tym roku.",
                        //         button: <Link title="Plac centralny, relaks w sercu osiedla idea" href="/aktualnosci/plac-centralny-relaks-w-sercu-osiedla-idea">Zobacz</Link>
                        //     }
                        // },
                        {
                            background: "/storage/img/hero/Header_Home.webp",
                            box: {
                                heading: "<span>WYBIERZ SAM</span><br/>Miejsce parkingowe za 50% ceny lub rabat na mieszkanie do <br/> 14 000 zł!",
                                button: <Link title="Kontakt" href="/kontakt#contact">Zapytaj o szczegóły</Link>
                            }
                        }
                    ]}
                    heading="Witaj na Osiedlu Idea"
                    bgPosition="md:bg-center"
                    bgPositionMobile="bg-[3%_25%]"
                    box={{
                        description:
                        "Osiedle Idea w Radomiu to część spójnej wizji architektonicznej realizowanej przez Unidevelopment od 2016 roku. W jej ramach na zielonym Wacynie tworzymy komfortową dzielnicę mieszkaniową położoną blisko centrum (zaledwie 2,8 km), z kameralnymi budynkami, wygodnymi drogami i pięknymi terenami zielonymi. Projekt osiedla nawiązuje do nowoczesnej koncepcji urbanistycznej <strong>miasta 15-minutowego</strong> realizowanej obecnie z powodzeniem, m.in. w Paryżu.",
                    }}
                /> */}

                {/* <HeroWithImage
                    background="/storage/img/hero/Header_Home.jpg"
                    heading="Witaj na Osiedlu Idea"
                    bgPosition="bg-center"
                    box={{
                        description:
                        "Osiedle Idea w Radomiu to część spójnej wizji architektonicznej realizowanej przez Unidevelopment od 2016 roku. W jej ramach na zielonym Wacynie tworzymy komfortową dzielnicę mieszkaniową położoną blisko centrum (zaledwie 2,8 km), z kameralnymi budynkami, wygodnymi drogami i pięknymi terenami zielonymi. Projekt osiedla nawiązuje do nowoczesnej koncepcji urbanistycznej <strong>miasta 15-minutowego</strong> realizowanej obecnie z powodzeniem, m.in. w Paryżu.",
                    }}
                    // banner={{
                    //     img: '/storage/banners/baner-dzien-otwarty-desktop.jpg',
                    //     link: 'https://unidevelopment.pl/dzien-otwarty-idea-ogrody',
                    //     external: true,
                    // }}
                /> */}
            </section>
            {/* {showPromo &&
                <PromoBanner 
                    show={showPromo} 
                    className="lg:-mt-13 mb-12"
                    slides={[
                        // {
                        //     img: '/storage/banners/baner-idealne-uslugi-desktop.jpg',
                        //     link: route('front.apartments'),
                        //     external: false,
                        // },
                        {
                            img: '/storage/banners/lokale-uslugowe-desktop.png',
                            link: route('front.apartments'),
                            external: false,
                        },
                        {
                            img: '/storage/banners/ostatnie-mieszkania-ogrody-desktop.png',
                            link: route('front.discover-estates.single', {slug: 'ogrody'}),
                            external: false,
                        },
                        // {
                        //     img: '/storage/banners/baner-dzien-otwarty-desktop.jpg',
                        //     link: 'https://unidevelopment.pl/dzien-otwarty-idea-ogrody',
                        //     external: true,
                        // },
                    ]}
                />
            } */}
            {/* <section id="cards" className="md:py-13 py-7">
                <IconHeadingSection className="mb-6">
                    <h2>Idealne miejsce dla Ciebie i Twojej rodziny!</h2>
                </IconHeadingSection>
                <PinCards
                    cards={[
                        {
                            image: {
                                src: "/storage/img/icons/plac.svg",
                                alt: "Plac",
                            },
                            description:
                                "Dziecięca przestrzeń do zabawy blisko domu? Wiemy jak ważne jest dla rodziców, by mieć pociechy „na oku”. Z myślą o najmłodszych mieszkańcach tworzymy kolejne place zabaw zlokalizowane przy poszczególnych etapach osiedla.",
                        },
                        {
                            image: {
                                src: "/storage/img/icons/Park.svg",
                                alt: "Park",
                            },
                            description:
                                "Planujemy duży park, akwen wodny, place zabaw, boiska i miejsca piknikowe. To wszystko już niedługo będzie czekać na Ciebie i Twoją rodzinę!",
                        },
                        {
                            image: {
                                src: "/storage/img/icons/sklepy.svg",
                                alt: "Sklepy",
                            },
                            description:
                                "Zgodnie z koncepcją <strong>15-minutowego miasta</strong> wiele elementów niezbędnych w codziennym życiu np. szkoły, sklepy, przychodnie czy komunikacja miejska znajduje się w zasięgu 15-minutowego spaceru od Twojego osiedla. Nie trać czasu na dojazdy!",
                        },
                    ]}
                />
            </section> */}

            <section id="investment-description">
                <InvestmentDescription
                    image={{ src: InvestmentDescriptionImage }}
                    imageWebp={{ src: "/storage/img/Photo-investment.webp" }}
                    carouselSectionHeading='Aktualnie w sprzedaży'
                    heading="Osiedle Idea to nie tylko nowoczesne mieszkania, to styl życia, który warto poznać"
                    button={{
                        url: route("front.discover-estates"),
                        text: "Zapoznaj się z naszą inwestycją",
                    }}
                    contentLeft={[
                        {
                            text: "W zielonym zaciszu Radomia, na Wacynie, budujemy dla Ciebie nowe osiedle. Idea jest prosta: stworzyć miejsce do życia, 15-minutowe miasteczko, które połączy naturę oraz dobrosąsiedzką aurę z nowoczesnymi rozwiązaniami urbanistycznymi.",
                        },
                        {
                            text: "Powstaje tu komfortowa i przyjazna przestrzeń, która łączy funkcje mieszkalne, usługowe i rekreacyjne. Oferująca wszystko co potrzebne do życia i relaksu bez konieczności oddalania się od miejsca zamieszkania.",
                        },
                    ]}
                    contentRight={[
                        {
                            text: "Centralnym punktem osiedla będzie plac z lokalną strefą drobnych usług, sklepików i kawiarni. Znajdą się tutaj także tereny rekreacyjne i pięknie zagospodarowana zieleń otaczająca zbiorniki wodne w obrębie osiedla.",
                            // list: [
                            //     {
                            //         text: (
                            //             <>
                            //                 <strong>wypoczynkowych</strong>{" "}
                            //                 (duży park i akwen wodny),
                            //             </>
                            //         ),
                            //     },
                            //     {
                            //         text: (
                            //             <>
                            //                 <strong>rekreacyjnych</strong>{" "}
                            //                 (place zabaw, boiska, miejsca
                            //                 piknikowe)
                            //             </>
                            //         ),
                            //     },
                            //     {
                            //         text: <strong>oraz handlowych</strong>,
                            //     },
                            // ],
                        },
                    ]}
                    slides={estates}
                />
            </section>

            <section id="search-engine" className="md:pb-[106px] py-7">
                <IconHeadingSection className="mb-8">
                    Znajdź mieszkanie spełniające Twoje wymagania
                </IconHeadingSection>
                <iframe src="https://allinone-unidevelopment-idea.prod.resimo.io/#/?app=jeff&lang=pl" loading="lazy" allow="fullscreen" className="w-full h-fit-screen"></iframe>
            </section>

            <section id="gallery">
                <GalleryCarousel
                    title="Galeria Osiedla Idea"
                    slides={[
                        {
                            src: "/storage/img/estates/orion/UniDevelopment_Idea_p002.jpg",
                            alt: "Osiedle Venus",
                        },
                        {
                            src: "/storage/img/estates/orion/UniDevelopment_Idea_p006.jpg",
                        },
                        {
                            src: "/storage/img/estates/orion/UniDevelopment_Idea_p005.jpg",
                        },
                        {
                            src: "/storage/img/estates/orion/UniDevelopment_Idea_p002.jpg",
                        },
                        {
                            src: "/storage/img/estates/orion/UniDevelopment_Idea_p006.jpg",
                        },
                        {
                            src: "/storage/img/estates/orion/UniDevelopment_Idea_p005.jpg",
                        },
                    ]}
                    button={{ url: route('front.gallery'), text: "Przejdź do galerii" }}
                />
            </section>

            <section id="news">
                <LatestNews news={news}></LatestNews>
            </section>
            {choosenApartments.length > 0 &&
                <section id="choosen-apartments" className="mt-13">
                    <IconHeadingSection className="mb-6">
                        Polecane mieszkania
                    </IconHeadingSection>
                    <Container>
                        <Row className="gap-y-6">
                            {choosenApartments && choosenApartments.map((ap, i) => (
                                <Column xl={3} lg={3} md={6}>
                                    <div className="border-i-blue rounded-[4px] border-[1px]">
                                        <div className="relative">
                                            {ap.get_attachment?.layout ?
                                                <WebpPicture
                                                    src={`/storage/apartments/${ap.get_attachment.layout}`}
                                                    alt={`Lokale handlowy - ${ap.name}`}
                                                    className="w-full object-contain h-[233px]"
                                                />
                                                :
                                                <div className="bg-white w-full h-[233px]">
                                                    <Icon icon={`ic:home`} width={203} className="my-auto pt-[60px] mx-auto text-i-gray/10"/>
                                                </div>
                                            }
                                            {ap.status != 'Wolne' &&
                                                <div className="bg-i-orange inline-block absolute top-4 py-1 pl-8 rounded-r-[112px]">
                                                    <div className="flex items-center">
                                                        <b className="uppercase mr-2 text-i-black">Rezerwacja</b>
                                                        <InlineIcon icon={`ic:baseline-local-offer`} className="mr-2 text-i-black" width={24} />
                                                    </div>
                                                </div>
                                            }
                                            {ap.is_reserved != false &&
                                                <div className={`bg-i-gold inline-block absolute ${ap.status != 'Wolne' ? 'top-16' : 'top-4'} py-1 pl-8 rounded-r-[112px]`}>
                                                    <div className="flex items-center">
                                                        <b className="uppercase mr-2 text-i-black">Ukończony</b>
                                                        <InlineIcon icon={`ic:baseline-local-offer`} className="mr-2 text-i-black" width={24} />
                                                    </div>
                                                </div>
                                            }
                                            {ap.get_attachment?.apartment_card &&
                                                <a target="_blank" href={`/storage/apartments/${ap.get_attachment.apartment_card}`}>
                                                    <div className="bg-i-navy inline-block absolute bottom-4 right-0 py-1 pr-8 rounded-l-[112px]">
                                                        <div className="flex items-center">
                                                            <b className="uppercase mx-2 text-white">Pdf</b>
                                                            <InlineIcon icon={`ic:outline-insert-drive-file`} className="text-i-gold" width={24} />
                                                        </div>
                                                    </div>
                                                </a>
                                            }
                                            <div className="border-b-2 border-i-indigo"></div>
                                        </div>
                                        <div className="p-6">
                                            <b className="mb-1 text-i-black">Lokal {ap.name}</b>
                                            <p className="font-medium text-i-gray">
                                                metraż: <span className="text-i-indigo font-bold">{ap.area}</span> m<sup>2</sup>
                                            </p>
                                            <p className="text-i-black">{ap.investment}</p>
                                            <div className="border-b-[1px] border-i-navy my-4"></div>
                                            <div className="flex justify-center">
                                                <div className="w-1/2 pl-2 text-center ">
                                                    <b className="text-i-black">Piętro</b>
                                                    <p className="text-i-gray">{ap.floor !== null ? ap.floor : '-'}</p>
                                                </div>
                                                <div className="border-r-[1px] border-i-blue mx-2"></div>
                                                <div className="w-1/2 pr-2 text-center">
                                                    <b className="text-i-black">Budynek</b>
                                                    <p className="text-i-gray">{ap.building_number}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Column>
                            ))}
                        </Row>
                        <Row className="justify-center">
                            <Column xl={4}>
                                <Link title="Zobacz wszystkie aktualności" href={route('front.apartments.search')} className="mt-8 w-full inline-flex items-center px-4 py-3 bg-i-blue-to-indigo rounded-sm text-16 leading-6 font-bold text-i-light-gray text-center justify-center transition-all ease-in-out duration-300 gap-2 hover:opacity-80">
                                    Zobacz pozostałe wpisy
                                </Link>
                            </Column>
                        </Row>
                    </Container>
                </section>
            }

            <section id="map">
                <LocationMap
                    sectionHeading="Nasza lokalizacja"
                    iframe={
                        <>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d806.8424193815353!2d21.119903194447993!3d51.40962821587541!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471933203beaaaab%3A0x62deab966975a55!2sOsiedle%20Idea%20-%20sprzeda%C5%BC%20mieszka%C5%84%20Unidevelopment%20SA!5e0!3m2!1spl!2spl!4v1717004741858!5m2!1spl!2spl" width="600" height="450" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                            {/* <object title="Nasza lokalizacja" data="https://www.openstreetmap.org/export/embed.html?bbox=21.11820906400681%2C51.40852724022751%2C21.121749579906464%2C51.41005472167556&amp;layer=mapnik&amp;marker=51.409290987330124%2C21.11997932195663">
                            </object> */}
                        </>
                    }
                />
            </section>

            <section id="contact">
                <ContactForm />
            </section>

            {/* <Popup>
                <Link href={route('front.discover-estates.single', {slug: 'ogrody3'})}>
                    <WebpPicture
                        alt="Nowy etap w sprzedaży"
                        src="/storage/banners/p2.png"
                    />
                </Link>
            </Popup> */}

        </MainLayout>
    );
};

export default Home;
